import React, { useState, useEffect, memo } from 'react';
import { FormGroup, Label } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const ConnectedUser = memo(({
    companyId,
    onChange
}) => {
    const { t } = useTranslation();
    const [connectedUser, setConnectedUser] = useState(null);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchConnectedUser = async () => {
            try {
                const response = await axios.get('/users?limit=1000', { params: { userCompanyId: companyId } });
                const user = response?.data?.[0];
                if (user) {
                    setConnectedUser(user);
                }

            } catch (error) {
                console.error(error);
            }
        };

        const fetchUsers = async () => {
            try {
                const response = await axios.get('/users', { params: { role: 'ROLE_COMPANY' } });
                setUsers(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchUsers().then(() => {
            fetchConnectedUser();
        });
    }, [companyId]);

    return (
        <FormGroup>
            <Label>{t("Connected users")}</Label>
            <Select
                options={users}
                getOptionLabel={option => option.username + " - " + option.email}
                getOptionValue={option => option.id}
                value={connectedUser}
                isClearable
                onChange={selectedOption => {
                    setConnectedUser(selectedOption);
                    onChange(selectedOption);
                }}
            />
        </FormGroup>
    );
});

export default ConnectedUser;