import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Widget from "../../components/Widget/Widget";
import GoBack from "../../components/GoBack";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Button,
    FormGroup,
    Label,
    FormFeedback,
    Input,
    Col,
    Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelectField from "../../components/async-select";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import PrintButton from "./components/print-pdf";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
    quoteId: Yup.string().required("Quote ID is required"),
    typeId: Yup.string().required("Type ID is required"),
    statusId: Yup.string().required("Status ID is required"),
    buyerCompanyId: Yup.string().required("Buyer Company ID is required"),
    sellerCompanyId: Yup.string().required("Seller Company ID is required"),
    tax: Yup.number().required("Tax is required").nullable(),
    totalSumExcTax: Yup.number().required("Total Sum Excluding Tax is required").nullable(),
    dateStartString: Yup.date().required("Start Date is required").nullable(),
    dateEndString: Yup.date().required("End Date is required").nullable(),
});

const QuoteForm = () => {
    const { t } = useTranslation();
    const { orderId } = useParams();
    const { currentUser } = useSelector((state) => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Fetch data orderId is provided
    const fetchOrder = async () => {
        try {
            const response = await axios.get(`/orders/${orderId}`);
            const order = response.data;
            formik.setValues({
                ...order,
                dateStartString: order?.dateStart,
                dateEndString: order?.dateEnd,
            })


        } catch (error) {
            console.error("Error fetching order:", error);
            toast.error(t("Failed to fetch order. Please try again."));
        }
    };

    useEffect(() => {
        if (orderId) {


            fetchOrder();
        }
    }, [orderId]);


    const formik = useFormik({
        initialValues: {
            quoteId: "",
            typeId: "",
            statusId: "",
            buyerCompanyId: "",
            sellerCompanyId: "",
            tax: "",
            totalSumExcTax: "",
            dateStartString: "",
            dateEndString: ""
        },
        validationSchema,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                dateStartString: moment(values.dateStartString).format("YYYY-MM-DD"),
                dateEndString: moment(values.dateEndString).format("YYYY-MM-DD"),
            };
            try {
                setIsSubmitting(true);
                const { data: { item } } = await axios.post(`/orders/${orderId || ""}`, formattedValues);
                formik.setValues({
                    ...item,
                    dateStartString: item?.dateStart,
                    dateEndString: item?.dateEnd,
                })
                if (orderId) {
                    toast.success(t("Order successfully updated"));
                } else {
                    toast.success(t("Order successfully created"));
                }

            } catch (error) {
                console.error("Error submitting form:", error);
                toast.error(t("Failed to submit order. Please try again."));
            } finally {
                setIsSubmitting(false);
            }
        },
    });

    const role = useMemo(() => currentUser?.role, [currentUser]);


    return (
        <div>
            <GoBack />
            <Widget title={

                <Row>
                    <Col md={6}><h3>{t(!orderId ? "Create order" : "Edit")}</h3></Col>
                    <Col md={6} className="d-flex justify-content-end">
                        <PrintButton orderId={orderId} type="pdf" />
                    </Col>
                </Row>


            }>
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="companyId">{t("Order count nr")}</Label>
                                <div>
                                    <Input type="text" value={formik.values?.countNr} readOnly />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="userId">{t("Quote id")}</Label>
                                <div>
                                    {currentUser.role === "ROLE_ADMIN" ? <Link to={`/admin/quotes/${formik.values?.quoteId}/edit`}>
                                        <i className="fa fa-edit me-2" />
                                        {formik.values?.quoteId}</Link> :
                                        <Input type="text" value={formik.values?.quoteId} readOnly />
                                    }
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="companyId">{t("Seller company")}</Label>
                                <div>
                                    {currentUser.role === "ROLE_ADMIN" ? (<Link to={`/admin/companies/${formik.values?.sellerCompanyId}/edit`}>
                                        <i className="fa fa-edit me-2" />
                                        {formik.values?.sellerCompanyName}</Link>)
                                        : <Input type="text" value={formik.values?.sellerCompanyName} readOnly />
                                    }

                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="userId">{t("Buyer company")}</Label>
                                <div>
                                    {currentUser.role === "ROLE_ADMIN" ? <Link to={`/admin/companies/${formik.values?.buyerCompanyId}/edit`}>
                                        <i className="fa fa-edit me-2" />
                                        {formik.values?.buyerCompanyName}</Link> :
                                        <Input type="text" value={formik.values?.buyerCompanyName} readOnly />
                                    }

                                </div>
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="dateStartString">
                                    {t("Start Date")}
                                </Label>
                                <div>
                                    <DatePicker
                                        wrapperClassName="w-100"
                                        style={{ width: "100%" }} // Full-width style

                                        id="dateStartString"
                                        selected={formik.values?.dateStartString ? new Date(formik.values?.dateStartString) : null}
                                        onChange={(date) =>
                                            formik.setFieldValue(
                                                "dateStartString",
                                                date
                                            )
                                        }
                                        onBlur={() =>
                                            formik.setFieldTouched(
                                                "dateStartString"
                                            )
                                        }
                                        className={`form-control ${formik.touched.dateStartString &&
                                            formik.errors.dateStartString
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {formik.touched.dateStartString &&
                                        formik.errors.dateStartString && (
                                            <div className="text-danger mt-1">
                                                {t(formik.errors.dateStartString)}
                                            </div>
                                        )}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="dateEndString">
                                    {t("End Date")}
                                </Label>
                                <div>
                                    <DatePicker
                                        wrapperClassName="w-100"
                                        style={{ width: "100%" }} // Full-width style
                                        id="dateEndString"
                                        selected={formik.values?.dateEndString ? new Date(formik.values?.dateEndString) : null}
                                        format="yyyy-MM-dd"
                                        onChange={(date) =>
                                            formik.setFieldValue(
                                                "dateEndString",
                                                date
                                            )
                                        }
                                        onBlur={() =>
                                            formik.setFieldTouched("dateEndString")
                                        }
                                        className={`form-control ${formik.touched.dateEndString &&
                                            formik.errors.dateEndString
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {formik.touched.dateEndString &&
                                        formik.errors.dateEndString && (
                                            <div className="text-danger mt-1">
                                                {t(formik.errors.dateEndString)}
                                            </div>
                                        )}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="totalSumExcTax">
                                    {t("Total Sum Excluding Tax")}
                                </Label>
                                <Input
                                    id="totalSumExcTax"
                                    name="totalSumExcTax"
                                    type="number"
                                    value={formik.values.totalSumExcTax}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                        formik.touched.totalSumExcTax &&
                                        !!formik.errors.totalSumExcTax
                                    }
                                />
                                {formik.touched.totalSumExcTax &&
                                    formik.errors.totalSumExcTax && (
                                        <FormFeedback>
                                            {t(formik.errors.totalSumExcTax)}
                                        </FormFeedback>
                                    )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="tax">
                                    {t("Tax")}
                                </Label>
                                <Input
                                    id="tax"
                                    name="tax"
                                    type="number"
                                    value={formik.values.tax}
                                    readOnly
                                />

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="statusId">{t("Status")}</Label>
                                <AsyncSelectField
                                    url="/options-list/order_status"
                                    defaultValue={formik.values.statusId}
                                    handleSelectChange={(selectedOption) =>
                                        formik.setFieldValue("statusId", selectedOption.id)
                                    }
                                />
                                {formik.touched.statusId && formik.errors.statusId && (
                                    <div className="text-danger mt-1">
                                        {t(formik.errors.statusId)}
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="oresutjamning">
                                    {t("oresutjamning")}
                                </Label>
                                <Input
                                    id="oresutjamning"
                                    name="oresutjamning"
                                    type="number"
                                    value={formik.values.oresutjamning}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                        formik.touched.tax &&
                                        !!formik.errors.tax
                                    }


                                />
                                {formik.touched.oresutjamning &&
                                    formik.errors.oresutjamning && (
                                        <FormFeedback>
                                            {t(formik.errors.oresutjamning)}
                                        </FormFeedback>
                                    )}

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="totalSumIncTax">
                                    {t("Comission")}
                                </Label>
                                <Input
                                    id="Comission"
                                    name="Comission"
                                    type="text"
                                    value={formik.values.comission}
                                />

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="totalSumIncTax">
                                    {t("Total sum incl tax")}
                                </Label>
                                <Input
                                    id="totalSumIncTax"
                                    name="totalSumIncTax"
                                    type="number"
                                    value={formik.values.totalSumIncTax}
                                    readOnly

                                />

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={2}> <Button type="submit" color="primary">
                            {isSubmitting ? <i className="fa fa-spin me-2 fa-spinner" /> : ""}
                            {t("Update")}
                        </Button></Col>



                    </Row>

                </form>
            </Widget>
        </div>
    );
};

export default QuoteForm;
