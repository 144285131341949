

const usersFields = {
    id: { type: 'id', label: 'ID' },
    username: {
        type: 'string', label: 'User name',
        required: true,
    },
    firstname: {
        type: 'string', label: 'First name',

    },
    lastname: {
        type: 'string', label: 'Last name',

    },
    phone: {
        type: 'string', label: 'Phone number',

    },
    email: {
        type: 'string', label: 'E-mail',
        required: true,

    },
    active: {
        type: 'boolean', label: 'Active',

    },
    personalNr: {
        type: 'string', label: 'Personal number',

    },
    role: {
        type: 'enum', label: 'Role',

        options: [

            { value: 'ROLE_ADMIN', label: 'Admin' },
            { value: 'ROLE_COMPANY', label: 'User' },
        ],

    },
    // avatar: { type: 'images', label: 'Avatar',

    //     },
    plainPassword: {
        type: 'plainPassword', label: 'Password'

    },
    confirmPassword: {
        type: 'confirmPassword', label: 'Confirm password',

    },
    // emailVerified: { type: 'boolean', label: 'emailVerified',

    //     },
    // emailVerificationToken: { type: 'string', label: 'emailVerificationToken',

    //     },
    // emailVerificationTokenExpiresAt: { type: 'datetime', label: 'emailVerificationTokenExpiresAt',

    //     },
    // passwordResetToken: { type: 'string', label: 'passwordResetToken',

    //     },
    // passwordResetTokenExpiresAt: { type: 'datetime', label: 'passwordResetTokenExpiresAt',

    //     },

}

export default usersFields;
