import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import axios from "axios";
import { Button, Input, Form, FormGroup, Label, Row, Col, Container, Spinner } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import useFetch from "../../components/hooks/use-fetch";
import Widget from "../../components/Widget/Widget";
import { useTranslation } from "react-i18next"; // Translation hook
import { toast } from "react-toastify";
import config from "../../config";
import moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const PdfViewerWithSignature = () => {
    const { token } = useParams();
    const [textareaValue, setTextareaValue] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // Submission state
    const signatureRef = React.createRef();
    const { t } = useTranslation(); // Translation hook
    const { currentUser } = useSelector(state => state.auth);

    const { data, loading, errors, refetch } = useFetch(`/quotes/esign/${token}`);

    const handleClearSignature = () => {
        signatureRef.current.clear();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (signatureRef.current.isEmpty()) {
            toast.error(t("Please sign before submitting."));
            return;
        }

        setIsSubmitting(true); // Set loading state

        const signatureData = signatureRef.current.toDataURL();
        const payload = {
            signatureImage: signatureData,
            comment: textareaValue,
            pdfFormTotalPages: data?.pdfFormTotalPages,
        };

        const endpoint =
            data.tokenType === "buyer"
                ? `/quotes/esign/${data.esignBuyerToken}`
                : `/quotes/esign/${data.esignSellerToken}`;

        try {
            await axios.post(endpoint, payload);
            toast.success(t("Signature submitted successfully!"));
            refetch();

        } catch (error) {
            toast.error(error.response?.data?.message || t("Something went wrong!"));
        } finally {
            setIsSubmitting(false);
        }
    };

    // write hook to check expiry date
    const isExpired = useMemo(() => {
        if (data?.esignEndDate) {
            return new Date(data.esignEndDate) < new Date();
        }
        return false;
    }, [data]);

    if (loading) {
        return (
            <LoadingWrapper>
                <Spinner color="primary" />
                <p>{t("Loading data, please wait...")}</p>
            </LoadingWrapper>
        );
    }

    return (
        <Container>

            <h3 className="mt-5 mb-5">{t(data?.name)}</h3>
            <Form onSubmit={handleSubmit}>
                <div>
                    {data?.pdfFormImages.map((pdfFormImage, index) => (
                        <ImageWrapper key={index}>
                            <PageNumber>{t(`Page`) + " " + (index + 1)}</PageNumber>
                            <StyledImage
                                src={`data:image/jpeg;base64,${pdfFormImage}`}
                                alt={t(`PDF Page ${index + 1}`)}
                            />
                        </ImageWrapper>
                    ))}
                </div>
                <Row>
                    <Col md={6}>
                        <h3>{data?.name}</h3>
                        <FormGroup>
                            <Label>{t("Quote ID")}: <Link to={
                                currentUser.role === "ROLE_COMPANY" ? `/app/quotes/${data.id}/edit` : `/admin/quotes/${data.id}/edit`
                            } >{data.id}</Link></Label>
                        </FormGroup>
                       
                        <FormGroup>
                            <Label>{t("Created date")}: {moment(data.esignStartDate).format("YYYY MM DD")}</Label>
                        </FormGroup>

                        <FormGroup>
                            <Label>{t("Expired date")}: {moment(data.esignEndDate).format("YYYY MM DD")}</Label>
                        </FormGroup>
                        

                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="esignBuyerName">{t("Buyer signature name")}</Label>
                            <Input
                                type="text"
                                id="esignBuyerName"
                                value={data?.esignBuyerName || ""}
                                readOnly
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="esignBuyerEmail">{t("Buyer signature email")}</Label>
                            <Input
                                type="email"
                                id="esignBuyerEmail"
                                value={data?.esignBuyerEmail || ""}
                                readOnly
                            />
                        </FormGroup>
                        {(data?.esignBuyerSignature && data?.esignBuyerSignature !== "null") && <div className="mt-4"><SignatureWrapper>
                            <img style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                            }} src={config.baseURL + "/" + (data?.esignBuyerSignature)} alt={t("Buyer Signature")} />
                        </SignatureWrapper>

                         
                        </div>
                        }


                    </Col>

                    


                    <Col md={6}>
                    <hr className="d-md-none" />
                        <FormGroup>
                            <Label for="esignSellerName">{t("Seller signature name")}</Label>
                            <Input
                                type="text"
                                id="esignSellerName"
                                value={data?.esignSellerName || ""}
                                readOnly
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="esignSellerEmail">{t("Seller signature email")}</Label>
                            <Input
                                type="email"
                                id="esignSellerEmail"
                                value={data?.esignSellerEmail || ""}
                                readOnly
                            />
                        </FormGroup>


                        {(data?.esignSellerSignature && data?.esignSellerSignature !== "null") && <div className="mt-4"><SignatureWrapper>
                            <img style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                            }} src={config.baseURL + "/" + (data?.esignSellerSignature)} alt={t("Buyer Signature")} />
                        </SignatureWrapper>
                        </div>
                        }
                    </Col>
                </Row>



                {
                    ((data?.tokenType === "buyer" && (!data?.esignBuyerSignature || data?.esignBuyerSignature === "null"))

                        ||

                        (data?.tokenType === "seller" && (!data?.esignSellerSignature || data?.esignSellerSignature === "null"))) && <>
                        <hr />
                        <FormGroup>
                            <Label for="comment">{t("Comment")}</Label>
                            <Input
                                type="textarea"
                                id="comment"
                                value={textareaValue}
                                onChange={(e) => setTextareaValue(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{t("Signature")}</Label>
                            <SignatureWrapper>
                                <SignatureCanvas
                                    ref={signatureRef}
                                    penColor="black"
                                    canvasProps={{
                                        style: {
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }, className: "sigCanvas"
                                    }}
                                />
                            </SignatureWrapper>
                            <Button size="xs" type="button" color="danger" onClick={handleClearSignature}>
                                {t("Clear")}
                            </Button>
                        </FormGroup>

                        <Button className="mb-5" type="submit" color="success" disabled={isSubmitting || isExpired}>
                            {isSubmitting && (
                                <i className="fa fa-spin fa-spinner me-2" />
                            )
                            }
                            {t("Submit")}
                        </Button>
                    </>
                }

                {((data?.tokenType === "buyer" && data?.esignBuyerSignature && data?.esignBuyerSignature !== "null")
                    ||

                    (data?.tokenType === "seller" && data?.esignSellerSignature && data?.esignSellerSignature !== "null")) && <div className="mt-4">

                        <SuccessMessage>
                            <i className="fa fa-check-circle" /> {t("You have successfully signed the document.")}
                        </SuccessMessage>
                    </div>
                }
            </Form>

        </Container>
    );
};

export default PdfViewerWithSignature;

// Styled Components
const ImageWrapper = styled.div`
  position: relative;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
`;

const PageNumber = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 14px;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const SignatureWrapper = styled.div`
  border: 1px dashed #ccc;
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  position: relative;
`;


const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;

  p {
    margin-top: 10px;
    font-size: 16px;
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: green;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  i {
    margin-right: 10px;
  }
`;