import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { getHistory } from '../index';
import { AdminRoute, UserRoute, AuthRoute } from './RouteComponents';

/* eslint-disable */
import ErrorPage from '../pages/error';
/* eslint-enable */

import '../styles/theme.scss';
import LayoutComponent from '../components/Layout/LayoutSeller';
import LayoutAdmin from '../components/Layout';
import Login from '../pages/auth/login';
import Verify from '../pages/auth/verify';
import Register from '../pages/auth/register';
import Reset from '../pages/auth/reset';
import Forgot from '../pages/auth/forgot';
import Esign from '../pages/esign';

const CloseButton = ({ closeToast }) => <i onClick={closeToast} className="la la-close notifications-close" />;

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const loadingInit = useSelector((state) => state.auth.loadingInit);

  if (loadingInit) {
    return <div />;
  }

  return (
    <div>
      <ToastContainer
        autoClose={5000}
        hideProgressBar
        closeButton={<CloseButton />}
      />
      <ConnectedRouter history={getHistory()}>
       
          <Switch>
            <Route path="/" exact render={() => <Redirect to="/app" />} />
       
            <AdminRoute path="/admin" currentUser={currentUser} dispatch={dispatch} component={LayoutAdmin} />
            <UserRoute path="/app" currentUser={currentUser} dispatch={dispatch} component={LayoutComponent} />
            <AuthRoute path="/register" exact component={Register} />
            <AuthRoute path="/login" exact component={Login} />
            <AuthRoute path="/verify-email" exact component={Verify} />
            <AuthRoute path="/password-reset/:token" exact component={Reset} />
            <AuthRoute path="/forgot" exact component={Forgot} />
            <Route path="/error" exact component={ErrorPage} />
            <Route path="/esign/:token" exact component={Esign} /> {/* New route */}
            <Redirect from="*" to="/app" />
          </Switch>
       
      </ConnectedRouter>
    </div>
  );
};

export default App;
