import { logoutUser } from '../actions/auth';
import { Redirect, Route } from 'react-router';
import React from 'react';

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return true;
  }
  return false;

}

// export const AdminRoute = ({ currentUser, dispatch, component, ...rest }) => {
//   console.log("admin route");
//   if (!currentUser || currentUser?.role !== 'ROLE_ADMIN' || !isAuthenticated(localStorage.getItem('token'))) {
//     return (<Redirect to="/app" />)
//   } else if (currentUser && currentUser.role === 'ROLE_ADMIN') {
//     return (
//       <Route {...rest} render={props => (React.createElement(component, props))} />
//     );
//   }
// };

export const AdminRoute = ({currentUser, dispatch, component, ...rest}) => {
  if (!isAuthenticated() || !currentUser) {
    dispatch(logoutUser());
    return (<Redirect to="/login"/>)
  } else if (currentUser.role !== 'ROLE_COMPANY') {
    return (
      <Route {...rest} render={props => (React.createElement(component, props))}/>
    );
  } else {
    return (<Redirect to="/app"/>)
  }
};

export const UserRoute = ({ currentUser, dispatch, component, ...rest }) => {
  if (!isAuthenticated() || !currentUser) {
    dispatch(logoutUser());
    return;
  } else if (currentUser.role !== 'ROLE_COMPANY') {
    return <Redirect to="/admin" />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};


// export const UserRoute = ({ currentUser, dispatch, component, ...rest }) => {
//   console.log("user route");
//   if (!isAuthenticated()) {
//     dispatch(logoutUser());
//     return (<Redirect to="/login" />)
//   } 
//   else {
//     return ( // eslint-disable-line
//       <Route {...rest} render={props => (React.createElement(component, props))} />
//     );
//   }
// };

export const AuthRoute = ({ dispatch, component, ...rest }) => {
  const { from } = rest.location.state || { from: { pathname: '/app' } };

  if (isAuthenticated()) {
    return (
      <Redirect to={from} />
    );
  } else {
    return (
      <Route {...rest} render={props => (React.createElement(component, props))} />
    );
  }
};
