import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Hammer from 'rc-hammerjs';
import UserFormPage from '../../pages/users/form/UsersFormPage';
import UserListPage from '../../pages/users/list/UsersListPage';
import UserViewPage from '../../pages/users/view/UsersViewPage';
import CompanyListPage from '../../pages/companies/list';
import CompanyEditPage from '../../pages/companies/edit';
import { SidebarTypes } from '../../reducers/layout';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { openSidebar, closeSidebar, toggleSidebar } from '../../actions/navigation';
import s from './Layout.module.scss';
import { DashboardThemes } from '../../reducers/layout';
import BreadcrumbHistory from '../BreadcrumbHistory';
import CompanyInfo from '../../pages/companies/info';
import CompanyCategories from '../../pages/company-categories/list';
import CompanyCategoryEdit from '../../pages/company-categories/edit';
import EmailTemplates from '../../pages/email-templates/list';
import EmailTemplateEdit from '../../pages/email-templates/edit';
import QuoteList from '../../pages/quota/list';
import QuoteEdit from '../../pages/quota/edit';
import QuoteCreate from '../../pages/quota/create';
import Orders from '../../pages/orders/list';
import OrderDetail from '../../pages/orders/edit'
import Error from '../../pages/error/ErrorPage';
import FormQuote from "../../pages/forms";


class Layout extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dashboardTheme: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarStatic: false,
    sidebarOpened: false,
    dashboardTheme: DashboardThemes.DARK
  };
  constructor(props) {
    super(props);

    this.handleSwipe = this.handleSwipe.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    if (window.innerWidth <= 768 && this.props.sidebarStatic) {
      this.props.dispatch(toggleSidebar());
    }
  }

  handleSwipe(e) {
    if ('ontouchstart' in window) {
      if (e.direction === 4) {
        this.props.dispatch(openSidebar());
        return;
      }

      if (e.direction === 2 && this.props.sidebarOpened) {
        this.props.dispatch(closeSidebar());
        return;
      }
    }
  }

  render() {
    return (
      <div
        className={[
          s.root,
          this.props.sidebarStatic ? `${s.sidebarStatic}` : '',
          !this.props.sidebarOpened ? s.sidebarClose : '',
          'sing-dashboard',
          `dashboard-${(localStorage.getItem("sidebarType") === SidebarTypes.TRANSPARENT) ? "light" : localStorage.getItem("dashboardTheme")}`,
          `header-${localStorage.getItem("navbarColor") ? localStorage.getItem("navbarColor").replace('#', '') : 'FFFFFF'}`
        ].join(' ')}
      >
        <Sidebar />
        <div className={s.wrap}>
          <Header />
          {/* <Helper /> */}

          <Hammer onSwipe={this.handleSwipe}>
            {this.props.currentUser && <main className={s.content}>
              <BreadcrumbHistory url={this.props.location.pathname} />
                    <Route path="/admin" exact render={() => <Redirect to="/admin/companies" />} />
                    <Route path="/admin/edit_profile" exact component={UserFormPage} />
                    {/* <Route path="/app" exact render={() => <Redirect to="/app/users" />} /> */}
                    <Route path="/admin/users" exact component={UserListPage} />
                    <Route path="/admin/users/new" exact component={UserFormPage} />
                    <Route path="/admin/users/:id/edit" exact component={UserFormPage} />
                    <Route path="/admin/users/:id" exact component={UserViewPage} />
                    <Route path="/admin/companies" exact component={CompanyListPage} />
                    <Route path="/admin/companies/create" exact component={CompanyEditPage} />
                    <Route path="/admin/companies/:companyId/edit" exact component={CompanyEditPage} />
                    <Route path="/admin/companies/:companyId/info" exact component={CompanyInfo} />
                    <Route path="/admin/company-categories" exact component={CompanyCategories} />
                    <Route path="/admin/company-categories/create" exact component={CompanyCategoryEdit} />
                    <Route path="/admin/company-categories/:categoryId/edit" exact component={CompanyCategoryEdit} />
                    <Route path="/admin/email-templates" exact component={EmailTemplates} />
                    <Route path="/admin/email-templates/create" exact component={EmailTemplateEdit} />
                    <Route path="/admin/email-templates/:templateId/edit" exact component={EmailTemplateEdit} />
                    <Route path="/admin/quotes" exact component={QuoteList} />
                    <Route path="/admin/quotes/create" exact component={QuoteCreate} />
                    <Route path="/admin/quotes/:quoteId/edit" exact component={QuoteEdit} />
                    <Route path="/admin/orders" exact component={Orders} />
                    <Route path="/admin/orders/create" exact component={OrderDetail} />
                    <Route path="/admin/orders/:orderId/edit" exact component={OrderDetail} />
                    <Route path="/admin/404" exact component={Error} />
                    <Route path="/admin/:quoteId/form" exact component={FormQuote} />
            </main>}
            
          </Hammer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    dashboardTheme: store.layout.dashboardTheme,
    navbarColor: store.layout.navbarColor,
    sidebarType: store.layout.sidebarType,
    currentUser: store.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
