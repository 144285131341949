import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

const SwitchFormItem = ({
  name,
  schema,
  hint,
  size,
  inputProps,
  required = false,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const { label } = schema[name];

  const sizeLabelClassName =
    {
      small: 'col-form-label-sm',
      large: 'col-form-label-lg',
    }[size] || '';

  const showError = meta.touched && meta.error;

  return (
    <div className="form-group">
      {!!label && (
        <label
          className={`col-form-label ${
            required ? 'required' : ''
          } ${sizeLabelClassName}`}
          htmlFor={name}
        >
          {t(label)}
        </label>
      )}

      <div>
        <input
          type="checkbox"
          id={name}
          {...inputProps}
          checked={!!field.value}
          onChange={(event) => {
            helpers.setValue(event.target.checked);
            helpers.setTouched(true);
          }}
        />
        <label htmlFor={name}>&#160;</label>
      </div>

      {showError && (
        <div className="invalid-feedback">
          {meta.error || errorMessage}
        </div>
      )}

      {!!hint && (
        <small className="form-text text-muted">
          {hint}
        </small>
      )}
    </div>
  );
};

export default SwitchFormItem;
