import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [trigger, setTrigger] = useState(0);

    const fetch = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(url);
            setData(response.data);
            setError(null);
        } catch (err) {
            console.error(err);
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [url]);

    useEffect(() => {
        if (url) {
            fetch();
        }
    }, [url, trigger, fetch]);

    const refetch = () => setTrigger((prev) => prev + 1);

    return { data, loading, error, refetch };
};

export default useFetch;
