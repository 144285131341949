// import React from 'react';
// import PropTypes from 'prop-types';
// import { useField } from 'formik';

// const CheckboxFormItem = ({
//   name,
//   schema,
//   hint,
//   size,
//   inputProps,
//   errorMessage,
//   required = false,
// }) => {
//   const [field, meta, helpers] = useField(name);
//   const { label } = schema[name];

//   const sizeLabelClassName =
//     {
//       small: 'col-form-label-sm',
//       large: 'col-form-label-lg',
//     }[size] || '';

//   const showError = meta.touched && meta.error;

//   return (
//     <div className="form-group">
//       {!!label && (
//         <label
//           className={`col-form-label ${required ? 'required' : ''} ${sizeLabelClassName}`}
//           htmlFor={name}
//         >
//           {label}
//         </label>
//       )}

//       <div>
//         <input
//           type="checkbox"
//           id={name}
//           name={name}
//           checked={!!field.value}
//           onChange={(event) => {
//             helpers.setValue(event.target.checked);
//             helpers.setTouched(true);
//           }}
//           {...inputProps}
//         />
//       </div>

//       {showError && <div className="invalid-feedback">{meta.error || errorMessage}</div>}

//       {!!hint && <small className="form-text text-muted">{hint}</small>}
//     </div>
//   );
// };

// export default CheckboxFormItem;


import React from 'react';
import { useField } from 'formik';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const CheckboxFormItem = ({
  name,
  schema,
  hint,
  required = false,
  errorMessage,
  updateValueSubQ,
  currentSubQ
}) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);

  const { label, options } = schema[name];

  const showError = meta.touched && meta.error;

  const handleCheckboxChange = (value) => {
    const currentValues = Array.isArray(field.value) ? field.value : [];
    if (currentValues.includes(value)) {
      helpers.setValue(currentValues.filter((v) => v !== value));
    } else {
      helpers.setValue([...currentValues, value]);
    }
  };

  return (
    <div className="form-group">
      {!!label && (
        <label className={`col-form-label ${required ? 'required' : ''}`}>
          {t(label)}
        </label>
      )}

      <br />

      {options.map((option) => (
        <div key={option.value}>
          <div className='d-flex justify-content-between mb-2'>
            <div className="form-check form-check-inline">
              <input
                className={`form-check-input ${showError ? 'is-invalid' : ''}`}
                type="checkbox"
                id={`${name}-${option.value}`}
                name={name}
                value={option.value}
                checked={Array.isArray(field.value) && field.value.includes(option.value)}
                onChange={(e) => {
                  handleCheckboxChange(option.value);
                  if (updateValueSubQ) {
                    if (e.target.checked) {
                      updateValueSubQ(option.value);
                    } else {
                      updateValueSubQ(null);
                    }
                  }
                }}
                onBlur={() => helpers.setTouched(true)}
              />
              <label htmlFor={`${name}-${option.value}`} className="form-check-label">
                {t(option.label)}
              </label>
            </div>
            {(option?.hasSubQuestions && Array.isArray(field.value) && field.value.includes(option.value))
              && <Button size="xs" color={
                currentSubQ === option.value ? 'primary' : 'secondary'
              } onClick={() => {
                if (updateValueSubQ) {
                  updateValueSubQ(option.value);
                }
              }}>{t("Edit")}</Button>}
          </div>
          {!!option?.helpText && <div className="mb-2 ms-4"><small className="form-text text-muted">{option.helpText}</small></div>}
        </div>

      ))}

      {showError && (
        <div className="invalid-feedback">{meta.error || errorMessage}</div>
      )}

      {!!hint && <small className="form-text text-muted">{hint}</small>}

    </div>
  );
};

export default CheckboxFormItem;
