import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useTranslation } from "react-i18next";

const RadioFormItem = ({
  name,
  schema,
  hint,
  required = false,
  errorMessage,
}) => {
  const { t } = useTranslation();
  
  const [field, meta, helpers] = useField(name);

  const { label, options } = schema[name];

  const showError = meta.touched && meta.error;

  return (
    <div className="form-group">
      {!!label && (
        <label className={`col-form-label ${required ? 'required' : ''}`}>
          {t(label)}
        </label>
      )}

      <br />

      {options.map((option) => (
        <div key={option.value}>

        <div  className="form-check form-check-inline">
          <input
            className={`form-check-input ${showError ? 'is-invalid' : ''}`}
            type="radio"
            id={`${name}-${option.value}`}
            name={name}
            value={option.value}
            checked={field.value === option.value}
            onChange={() => helpers.setValue(option.value)}
            onBlur={() => helpers.setTouched(true)}
          />
          <label htmlFor={`${name}-${option.value}`} className="form-check-label">
            {t(option.label)}
          </label>
        </div>
        {!!option?.helpText && <div className="mb-2 ms-4"><small className="form-text text-muted">{option.helpText}</small></div>}
        </div>
      ))}

      {showError && (
        <div className="invalid-feedback">{meta.error || errorMessage}</div>
      )}

      {!!hint && <small className="form-text text-muted">{hint}</small>}
    </div>
  );
};

export default RadioFormItem;
