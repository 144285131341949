import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Col, Row, Button, ButtonToolbar } from "reactstrap";
import { useTranslation } from "react-i18next";
import qs from "qs";
import axios from "axios";
import InputFormItem from "../../components/FormItems/items/InputFormItem";
import FormValidations from "../../components/FormItems/formValidations";
import Widget from "../../components/Widget/Widget";
import Loader from "../../components/Loader/Loader";
import GoBack from "../../components/GoBack";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Editor } from '@tinymce/tinymce-react';
import config from "../../config";
export const EmailTemplateEdit = ({
    location,
    match: { params: { templateId } },
}) => {
    const { t } = useTranslation();
    const [emailTemplate, setEmailTemplate] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [query, setQuery] = useState({});
    const dispatch = useDispatch();

    const schema = {
        name: { type: "string", label: "Template name", required: true },
        emailKey: { type: "string", label: "Email key", required: true },
        emailSubject: { type: "string", label: "Subject", required: true }
    };

    useEffect(() => {
        const fetchEmailTemplate = async () => {
            try {
                const response = await axios.get(`/email-templates/${templateId}`);
                setEmailTemplate(response.data);
                setIsFetching(false);
            } catch (error) {
                console.error("Error fetching email template:", error);
                setIsFetching(false);
            }
        };

        if (templateId) {
            fetchEmailTemplate();
        } else {
            setIsFetching(false);
        }
    }, [templateId]);

    useEffect(() => {
        setQuery(qs.parse(location.search, { ignoreQueryPrefix: true }));
    }, [location]);

    const handleSubmit = async (values) => {
        setIsUpdating(true);
        try {
            if (templateId) {
                try {

                    await axios.post(`/email-templates/${templateId}`, values);
                    toast.success(t("Email template updated successfully"))


                } catch (error) {
                    toast.error(t("Error updating email template"))

                }

            } else {
                try {
                    const resp = await axios.post("/email-templates/", values);
                    toast.success(t("Email template created successfully"));
                    dispatch(push(`/admin/email-templates`));

                } catch (error) {
                    toast.error(t("Error creating email template"))
                }

            }
            setIsUpdating(false);
        } catch (error) {
            console.error(t("Error updating email template:"), error);
            setIsUpdating(false);
        }
    };

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div>
            <GoBack />
            <Widget title={<h3>{t(!templateId ? "Create new" : "Edit")}</h3>}>
                <Formik
                    onSubmit={handleSubmit}
                    enableReinitialize
                    initialValues={
                        emailTemplate || { name: "", emailKey: "", emailSubject: "", emailContent: "" }
                    }
                    validationSchema={() => FormValidations(schema)}
                >
                    {(form) => (
                        <Row>
                            <Col md={6}>
                                <Form onSubmit={form.handleSubmit}>
                                    <InputFormItem
                                        name={"name"}
                                        required
                                        schema={schema}
                                    />
                                    <InputFormItem
                                        name={"emailKey"}
                                        required
                                        schema={schema}
                                    />
                                    <InputFormItem
                                        name={"emailSubject"}
                                        required
                                        schema={schema}
                                    />
                                    <Field name="emailContent">
                                        {({ field }) => (
                                            <div className="form-group">
                                                <label>{t("Content")}</label>
                                                <Editor
                                                    apiKey={config.tinymceApiKey}
                                                    language_url={'../../locales/tinyMce.js'}
                                                    init={{
                                                        language: 'sv_SE',
                                                        plugins: [
                                                            // Core editing features
                                                            'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',

                                                        ],
                                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | forecolor backcolor | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                                    }}
                                                    value={field.value}
                                                    onEditorChange={(content, editor) => {
                                                        form.setFieldValue(field.name, content)
                                                    }}
                                                />

                                            </div>
                                        )}
                                    </Field>

                                    <ButtonToolbar className="mt-lg">
                                        <Button
                                            color="success"
                                            type="submit"
                                            disabled={isUpdating}
                                        >
                                            {isUpdating ? (
                                                <Loader />
                                            ) : !templateId ? (
                                                t("Create")
                                            ) : (
                                                t("Save")
                                            )}
                                        </Button>
                                        <button
                                            className="btn btn-light"
                                            type="button"
                                            onClick={form.handleReset}
                                        >
                                            {t("Reset")}
                                        </button>
                                    </ButtonToolbar>
                                </Form>
                            </Col>
                        </Row>
                    )}
                </Formik>
            </Widget>
        </div>
    );
};

export default EmailTemplateEdit;
