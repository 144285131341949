import React, { useState, useEffect, useRef } from "react";
import { Input } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    Button,
    ButtonToolbar,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row, Col, Table
} from "reactstrap";
import Loader from "../../components/Loader/Loader";
import GoBack from "../../components/GoBack";
import { useTranslation } from "react-i18next";
import axios from "axios";
import qs from "qs";

const EmailTemplates = () => {
    const location = useLocation();
    const itemsPerPage = 50;
    const fetch = async () => {
        setState((prev) => ({
            ...prev,
            isReceiving: true,
        }));

        try {
            const limit = itemsPerPage + 1;
            const offset = (query.page | 0) * (limit - 1);

            const response = await axios.get('/email-templates/', {
                params: {
                    ...query,
                    limit,
                    offset,
                },
            });

            const emailTemplates = response.data.slice(0, itemsPerPage);
            const hasNext = response.data.length > itemsPerPage;

            setState((prev) => ({
                ...prev,
                emailTemplates,
                isReceiving: false,
                hasNext,
            }));
        } catch (error) {
            setState((prev) => ({
                ...prev,
                isReceiving: false,
            }));
        }
    };

    const timeoutRef = useRef(null);
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = useState({
        modalOpen: false,
        idToDelete: null,
        emailTemplates: [],
        isReceiving: true,
        hasMore: false,
        hasNext: false, // To determine if there are more pages
    });

    const [query, setQuery] = useState(qs.parse(location.search, { ignoreQueryPrefix: true }))



    const search = (e) => {
        let { value } = e.target;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);

        }
        timeoutRef.current = setTimeout(() => {
            history.push({
                pathname: `/admin/email-templates`,
                search: qs.stringify({
                    ...state.query,
                    page: 0,
                    q: value,
                }),
            });
        }, 500);

    };

    useEffect(() => {
        setQuery(qs.parse(location.search, { ignoreQueryPrefix: true }))
    }, [location]);


    useEffect(() => {
        fetch();
    }, [query]);


    const handleDelete = async () => {
        setState((prev) => ({
            ...prev,
            isDeleting: true,
        }));
        try {
            await axios.delete(`/email-templates/${state.idToDelete}`);
            setState((prev) => ({
                ...prev,
                modalOpen: false,
                idToDelete: null,
                isDeleting: false,
            }));
            fetch();
        } catch (error) {
            console.error("Error deleting email template:", error);
            setState((prev) => ({
                ...prev,
                isDeleting: false,
            }));
        }
    };

    const openModal = (cell) => {
        setState((prev) => ({
            ...prev,
            modalOpen: true,
            idToDelete: cell,
        }));
    };

    const closeModal = () => {
        setState((prev) => ({
            ...prev,
            modalOpen: false,
        }));
    };


    const create = () => {
        history.push(
            `/admin/email-templates/create`
        );
    };

    const goToNextPage = () => {
        history.push({
            pathname: `/admin/email-templates`,
            search: qs.stringify({
                ...query,
                page: Number(query?.page ?? 0) + 1,
            }),
        });
    };

    const goToPreviousPage = () => {
        if (query.page > 0) {
            history.push({
                pathname: `/admin/email-templates`,
                search: qs.stringify({
                    ...query,
                    page: Number(query?.page ?? 0) - 1,
                }),
            });
        }
    };

    return (
        <div>
            <GoBack />
            <Row className="filter align-items-center mb-4">
                <Col md={3} xs={12}>
                    <h2>{t("Email templates")}</h2>
                </Col>
                <Col md={3} xs={12}>
                    <Input className="bg-white" placeholder={t("Search")} value={state.searchStr} onChange={search} />
                </Col>
                <Col xl={3} xs={12}></Col>
                <Col className="d-flex justify-content-end" md={3} xs={12}>
                    <Button color="primary" onClick={() => create()}>
                        {t("Create new")}
                    </Button>
                </Col>
            </Row>
            <>
                <Table className="table-bordered  table-hover table-lg">
                    <thead>
                        <tr>
                            <th>{t("Name")}</th>
                            <th>{t("Email Key")}</th>
                            <th>{t("Subject")}</th>
                            <th>{t("Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.isReceiving ? (
                            <tr>
                                <td className="text-center" colSpan={3}> <Loader size={25} /></td>
                            </tr>

                        ) : <>  {state.emailTemplates.length === 0 && (
                            <tr>
                                <td className="text-center" colSpan={3}>{t("No data")}</td>
                            </tr>
                        )}
                            {state.emailTemplates.map((template) => (
                                <tr key={template.id}>
                                    <td>
                                        <Link
                                            to={`/admin/email-templates/${template.id}/edit`}
                                        >
                                            {template.name}
                                        </Link>
                                    </td>
                                    <td>{template.emailKey}</td>
                                    <td>{template.subject}</td>
                                    <td>
                                        <ButtonToolbar className="justify-content-end">
                                            <Button
                                                color="primary"
                                                size="xs"
                                                onClick={() =>
                                                    history.push(
                                                        `/admin/email-templates/${template.id}/edit`
                                                    )
                                                }
                                            >
                                                <span className="d-none d-md-inline-block">
                                                    {t("Edit")}
                                                </span>
                                                <span className="d-md-none">
                                                    <i className="la la-edit" />
                                                </span>
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    openModal(template.id);
                                                }}
                                                color="danger"
                                                size="xs"
                                            >
                                                {state.isDeleting &&
                                                    state.idToDelete ===
                                                    template.id ? (
                                                    <Loader size={14} />
                                                ) : (
                                                    <span>
                                                        <span className="d-none d-md-inline-block">
                                                            {t("Delete")}
                                                        </span>
                                                        <span className="d-md-none">
                                                            <i className="la la-remove" />
                                                        </span>
                                                    </span>
                                                )}
                                            </Button>
                                        </ButtonToolbar>
                                    </td>
                                </tr>
                            ))}</>}

                    </tbody>
                </Table>
            </>


            <Row className="mt-md">
                <Col md={6} xs={12}>
                    {query.page > 0 && (
                        <Button onClick={goToPreviousPage} color="primary">
                            <i className="fa mr-xs fa-angle-left" />
                            {t("Previous")}
                        </Button>
                    )}
                </Col>
                <Col md={6} xs={12} className="d-flex justify-content-end">
                    {state.hasNext && (
                        <Button onClick={goToNextPage} color="primary">
                            {t("Next")}
                            <i className="fa ml-xs fa-angle-right" />
                        </Button>
                    )}
                </Col>
            </Row>

            <Modal size="sm" isOpen={state.modalOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>
                    {t("Confirm delete")}
                </ModalHeader>
                <ModalBody className="bg-white">
                    {t("Are you sure you want to delete this item?")}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={closeModal}>
                        {t("Cancel")}
                    </Button>
                    <Button color="primary" onClick={handleDelete}>
                        {state.isDeleting ? <Loader /> : t("Delete")}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default EmailTemplates;
