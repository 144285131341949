import React from 'react';
import FormSubmit from './form';
import { useParams } from 'react-router-dom';
const Form = () => {
    const {quoteId} = useParams();
    return (
        <FormSubmit quoteId={quoteId} />
    );
};

export default Form;
