import React, { useState, useEffect, useRef } from "react";
import { Input } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    Button,
    ButtonToolbar,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row, Col, Table
} from "reactstrap";
import Loader from "../../components/Loader/Loader";
import GoBack from "../../components/GoBack";
import { useTranslation } from "react-i18next";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import Widget from "../../components/Widget/Widget";

const Orders = () => {
    const location = useLocation();
    const itemsPerPage = 50;
    const fetch = async () => {
        setState((prev) => ({
            ...prev,
            isReceiving: true,
        }));

        try {
            const limit = itemsPerPage + 1;
            const offset = (query.page | 0) * (limit - 1);

            const response = await axios.get('/orders/', {
                params: {
                    ...query,
                    limit,
                    offset,
                },
            });

            const orders = response.data.slice(0, itemsPerPage);
            const hasNext = response.data.length > itemsPerPage;

            setState((prev) => ({
                ...prev,
                orders: orders,
                isReceiving: false,
                hasNext,
            }));
        } catch (error) {
            setState((prev) => ({
                ...prev,
                isReceiving: false,
            }));
        }
    };

    const timeoutRef = useRef(null);
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = useState({
        modalOpen: false,
        idToDelete: null,
        orders: [],
        isReceiving: true,
        hasMore: false,
        hasNext: false, // To determine if there are more pages
    });

    const [query, setQuery] = useState(qs.parse(location.search, { ignoreQueryPrefix: true }))



    const search = (e) => {
        let { value } = e.target;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);

        }
        timeoutRef.current = setTimeout(() => {
            history.push({
                pathname: `/admin/orders`,
                search: qs.stringify({
                    ...state.query,
                    page: 0,
                    q: value,
                }),
            });
        }, 500);

    };

    useEffect(() => {
        setQuery(qs.parse(location.search, { ignoreQueryPrefix: true }))
    }, [location]);


    useEffect(() => {
        fetch();
    }, [query]);


    const handleDelete = async () => {
        setState((prev) => ({
            ...prev,
            isDeleting: true,
        }));
        try {
            await axios.delete(`/orders/${state.idToDelete}`);
            setState((prev) => ({
                ...prev,
                modalOpen: false,
                idToDelete: null,
                isDeleting: false,
            }));
            fetch();
        } catch (error) {
            setState((prev) => ({
                ...prev,
                isDeleting: false,
            }));
        }
    };

    const openModal = (cell) => {
        setState((prev) => ({
            ...prev,
            modalOpen: true,
            idToDelete: cell,
        }));
    };

    const closeModal = () => {
        setState((prev) => ({
            ...prev,
            modalOpen: false,
        }));
    };


    const create = () => {
        history.push(
            `/admin/orders/create`
        );
    };

    const goToNextPage = () => {
        history.push({
            pathname: `/admin/orders`,
            search: qs.stringify({
                ...query,
                page: Number(query?.page ?? 0) + 1,
            }),
        });
    };

    const goToPreviousPage = () => {
        if (query.page > 0) {
            history.push({
                pathname: `/admin/orders`,
                search: qs.stringify({
                    ...query,
                    page: Number(query?.page ?? 0) - 1,
                }),
            });
        }
    };

    return (
        <div>
            <GoBack />
            <Row className="filter align-items-center mb-4">
                <Col md={3} xs={12}>
                    <h2>{t("Orders")}</h2>
                </Col>
                <Col md={3} xs={12}>
                    <Input className="bg-white" placeholder={t("Search")} value={state.searchStr} onChange={search} />
                </Col>
                <Col xl={3} xs={12}></Col>
            </Row>
            <>
                <Widget>
                    <Table size="lg" responsive className="table-lg align-middle">
                        <thead>
                            <tr>
                               
                                <th className="ps-0 pt-0 width-50">{t("#")}</th>
                                <th className="pt-0">{t("Buyer company")}</th>
                                <th className="pt-0">{t("Seller company")}</th>
                                <th className="pt-0">{t("Created date")}</th>
                                <th className="pt-0">{t("Date start")}</th>
                                <th className="pt-0">{t("Date end")}</th>
                                <th className="pt-0">{t("Status")}</th>
                                <th className="">{t("Total sum excl tax")}</th>
                                <th>{t("Quote")}</th>
                                <th className="pt-0">{t("Actions")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.isReceiving ? (
                                <tr>
                                    <td className="text-center" colSpan={8}> <Loader size={25} /></td>
                                </tr>

                            ) : <>  {state.orders.length === 0 && (
                                <tr>
                                    <td className="text-center" colSpan={8}>{t("No data")}</td>
                                </tr>
                            )}

                                {state.orders.map((order, index) => (
                                    <tr key={index}>
                                        <td className="ps-0">{order.countNr}</td>
                                        <td>{order?.sellerCompanyName ?? ""}</td>
                                        <td>{order?.buyerCompanyName ?? ""}</td>
                                        <td>{order?.dateCreated ? moment(order.dateCreated).format("YYYY-MM-DD"):""}</td>
                                        <td>{order?.dateStart ? moment(order.dateStart).format("YYYY-MM-DD"):""}</td>
                                        <td>{order?.dateEnd ? moment(order?.dateEnd).format("YYYY-MM-DD"): ""}</td>
                                        <td>{order.statusText}</td>
                                        <td>{order.totalSumExcTax}</td>
                                        <td>{order.quoteId}</td>
                                        <td className="width-150">
                                            <ButtonToolbar className="justify-content-end">
                                                <Button
                                                    color="primary"
                                                    size="xs"
                                                    onClick={() =>
                                                        history.push(
                                                            `/admin/orders/${order.id}/edit`
                                                        )
                                                    }
                                                >
                                                    <span className="d-none d-md-inline-block">
                                                        {t("Edit")}
                                                    </span>
                                                    <span className="d-md-none">
                                                        <i className="la la-edit" />
                                                    </span>
                                                </Button>

                                                <Button
                                                    onClick={() => {
                                                        openModal(order.id);
                                                    }}
                                                    color="danger"
                                                    size="xs"
                                                >
                                                    {state.isDeleting &&
                                                        state.idToDelete ===
                                                        order.id ? (
                                                        <Loader size={14} />
                                                    ) : (
                                                        <span>
                                                            <span className="d-none d-md-inline-block">
                                                                {t("Delete")}
                                                            </span>
                                                            <span className="d-md-none">
                                                                <i className="la la-remove" />
                                                            </span>
                                                        </span>
                                                    )}
                                                </Button>
                                            </ButtonToolbar>
                                        </td>
                                    </tr>
                                ))}</>}

                        </tbody>
                    </Table>
                    <Row >
                        <Col md={6} xs={12}>
                            {query.page > 0 && (
                                <Button onClick={goToPreviousPage} color="primary">
                                    <i className="fa mr-xs fa-angle-left" />
                                    {t("Previous")}
                                </Button>
                            )}
                        </Col>
                        <Col md={6} xs={12} className="d-flex justify-content-end">
                            {state.hasNext && (
                                <Button onClick={goToNextPage} color="primary">
                                    {t("Next")}
                                    <i className="fa ml-xs fa-angle-right" />
                                </Button>
                            )}
                        </Col>
                    </Row>

                </Widget>
            </>



            <Modal size="sm" isOpen={state.modalOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>
                    {t("Confirm delete")}
                </ModalHeader>
                <ModalBody className="bg-white">
                    {t("Are you sure you want to delete this item?")}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={closeModal}>
                        {t("Cancel")}
                    </Button>
                    <Button color="primary" onClick={handleDelete}>
                        {state.isDeleting ? <Loader /> : t("Delete")}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Orders;
