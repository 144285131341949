import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Navbar,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  DropdownItem,
} from 'reactstrap';
import { NavbarTypes } from '../../reducers/layout';
import { logoutUser } from '../../actions/auth';
import { toggleSidebar, openSidebar, closeSidebar, changeActiveSidebarItem } from '../../actions/navigation';

import adminDefault from '../../images/chat/chat2.png';
import Settings from '../../images/sidebar/basil/Settings';
import UserDefault from '../../images/sidebar/basil/UserDefault';
import PowerButton from '../../images/sidebar/basil/PowerButton';
import { useTranslation } from 'react-i18next';

import s from './Header.module.scss';
import config from '../../config';

import adamPatterson from '../../images/banners/adam-patterson.jpg';
import gustavoZambelli from '../../images/banners/gustavo-zambelli.jpg';
import jasonLeung from '../../images/banners/jason-leung.jpg';
import mariaOrlova from '../../images/banners/maria-orlova.jpg';
import samQuek from '../../images/banners/sam-quek.jpg';
import seanPollock from '../../images/banners/sean-pollock.jpg';
import shengHu from '../../images/banners/sheng-hu.jpg';
import taroOhtani from '../../images/banners/taro-ohtani.jpg';
import timMossholder from '../../images/banners/tim-mossholder.jpg';


const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    sidebarStatic,
    openUsersList,
    currentUser,
  } = useSelector((store) => ({
    sidebarStatic: store.navigation.sidebarStatic,
    openUsersList: store.chat.openUsersList,
    currentUser: store.auth.currentUser,
  }));

  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);


  const avatar = window.localStorage.getItem('userCompanyLogo') ? config.baseURL + "/" + window.localStorage.getItem('userCompanyLogo') : null;
  const firstUserLetter = currentUser && (currentUser.firstname || currentUser.email)?.[0]?.toUpperCase();


  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const doLogout = () => {
    dispatch(logoutUser());
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navbarType = localStorage.getItem('navbarType') || 'static';

  const { t } = useTranslation();

  const banners = [
    adamPatterson,
    gustavoZambelli,
    jasonLeung,
    mariaOrlova,
    samQuek,
    seanPollock,
    shengHu,
    taroOhtani,
    timMossholder
  ];

  return (
    <>
      <Navbar
        className={`${s.root} d-print-none ${navbarType === NavbarTypes.FLOATING ? s.navbarFloatingType : ''}`}
        style={{ zIndex: !openUsersList ? 100 : 0 }}
      >
        <div className="d-flex flex-row justify-content-md-start flex-grow-1 align-content-center align-self-start">
          <NavLink className={`${s.navbarBrand} d-md-none ${s.headerSvgFlipColor}`}>
            <i className="fa fa-circle text-primary me-n-sm" />
            <i className="fa fa-circle text-danger" />
            &nbsp; sing &nbsp;
            <i className="fa fa-circle text-danger me-n-sm" />
            <i className="fa fa-circle text-primary" />
          </NavLink>
        </div>

        <div>
          <Nav className="ms-auto">
            <Dropdown
              nav
              isOpen={notificationsOpen}
              toggle={toggleNotifications}
              id="basic-nav-dropdown"
              className={`${s.notificationsMenu}`}
            >
              <DropdownToggle nav caret className={s.headerSvgFlipColor}>
                <span className={`${s.avatar} rounded-circle float-start me-3`}>
                  {avatar ? (
                    <img
                      src={avatar}
                      onError={(e) => (e.target.src = adminDefault)}
                      alt="..."
                      title={currentUser?.firstname || currentUser?.email}
                    />
                  ) : (
                    <span title={currentUser?.firstname || currentUser?.email}>{firstUserLetter}</span>
                  )}
                </span>
                <span
                  className={`small m-2 d-sm-down-none ${s.headerTitle} ${sidebarStatic ? s.adminEmail : ''}`}
                >
                  {currentUser ? currentUser.email : ''}
                </span>
              </DropdownToggle>
            </Dropdown>

            <Dropdown
              nav
              isOpen={menuOpen}
              toggle={toggleMenu}
              className="tutorial-dropdown pr-4"
            >
              <DropdownToggle nav className={`${s.mobileCog}`}>
                <span className={`${s.headerSvgFlipColor}`}>
                  <Settings />
                </span>
              </DropdownToggle>
              <DropdownMenu end className={`${s.headerDropdownLinks} super-colors`}>
                {currentUser && <DropdownItem href={currentUser.role === "ROLE_COMPANY" ? "/app/edit_profile" : "/admin/edit_profile"}>
                  <span className={s.headerDropdownIcon}><UserDefault /></span>{t("My Account")}
                </DropdownItem>}

                <DropdownItem divider />
                <DropdownItem onClick={doLogout}>
                  <span className={s.headerDropdownIcon}><PowerButton /></span>{t("Log Out")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>
      {currentUser && currentUser.role === 'ROLE_COMPANY' && <div
        className={`${s.banner}`}
        style={{
          backgroundImage: `url('${banners[Math.floor(Math.random() * banners.length)]}')`,
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center center', 
          backgroundSize: 'cover',
        }}
      ></div>}

    </>
  );
};
export default Header;
