import React from 'react';
import Logo from '../../../images/logo.svg';
import { Form, Formik } from 'formik';
import formValidations from '../../../components/FormItems/formValidations';
import InputFormItem from '../../../components/FormItems/items/InputFormItem';
import { useParams } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import Widget from '../../../components/Widget';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../actions/auth';
import Loader from '../../../components/Loader/Loader';


const schema = {
  plainPassword: {
    type: 'plainPassword', label: 'Password'
  },
  confirmPassword: {
    type: 'confirmPassword', label: 'Confirm password',
  }
}

const Reset = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const { isFetching } = useSelector(state => state.auth);

  return (
    <div className="auth-page">
      <Container>
        <div className="auth-logo d-flex justify-content-center">
          <img src={Logo} alt={t("Logo")} />
        </div>
        <Widget className="widget-auth mx-auto" title={<h3 className="mt-0">{t("Reset password")}</h3>}>
          <Formik
            enableReinitialize
            initialValues={{ plainPassword: "", confirmPassword: "" }}
            validationSchema={() => formValidations(schema)}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(resetPassword(token, values.plainPassword));
            }}
          >
            {({ isValid, isSubmitting }) => (
              <Form>
                <Row>
                  <Col md={12}>
                    <InputFormItem
                      password
                      name="plainPassword"
                      required
                      schema={schema}
                    />
                  </Col>
                  <Col md={12}>
                    <InputFormItem
                      password
                      name="confirmPassword"
                      required
                      schema={schema}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      type="submit"
                      color="inverse"
                      className="auth-btn mb-3"
                      size="sm"
                      disabled={isFetching || !isValid}
                    >
                      {(isFetching) ? (
                        <Loader />
                      ) : t("Reset")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Widget>
      </Container>
    </div>
  );
};

export default Reset;
