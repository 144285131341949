import React from 'react'

const Stack = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.1546 3.36032C12.4835 3.01005 11.5741 3.00465 10.8977 3.35402C8.30469 4.69316 5.95282 6.22859 3.89185 7.9316C3.57314 8.19495 3.34696 8.57764 3.35082 9.02808C3.35466 9.47668 3.58544 9.85425 3.90222 10.1133C5.94607 11.7844 8.29988 13.3213 10.8454 14.6498C11.5165 15.0001 12.4259 15.0055 13.1024 14.6561C15.6953 13.317 18.0472 11.7815 20.1082 10.0785C20.4269 9.81517 20.6531 9.43248 20.6492 8.98203C20.6453 8.53344 20.4146 8.15586 20.0978 7.89685C18.0539 6.22568 15.7001 4.68886 13.1546 3.36032ZM11.586 4.68677C11.8256 4.563 12.2193 4.56417 12.4606 4.6901C14.8899 5.95802 17.1283 7.41672 19.0676 8.99229C17.1168 10.5932 14.885 12.0472 12.4141 13.3233C12.1744 13.4471 11.7807 13.4459 11.5394 13.32C9.11007 12.0521 6.87166 10.5934 4.93243 9.01783C6.88324 7.4169 9.11499 5.96291 11.586 4.68677Z" />
        <path d="M21.197 12.6981C21.4164 13.0494 21.3094 13.5121 20.958 13.7315L14.8508 17.5444C14.0221 18.0618 12.9939 18.301 11.9999 18.301C11.006 18.3011 9.97773 18.062 9.14887 17.5447L3.10854 13.775C2.75714 13.5557 2.65006 13.093 2.86937 12.7416C3.08867 12.3902 3.55132 12.2831 3.90271 12.5024L9.94304 16.2722C10.4872 16.6118 11.2264 16.8011 11.9998 16.801C12.7733 16.801 13.5124 16.6116 14.0564 16.272L20.1637 12.4591C20.515 12.2397 20.9777 12.3467 21.197 12.6981Z" />
        <path d="M21.197 16.4527C21.4164 16.8041 21.3094 17.2667 20.9581 17.4861L15.6692 20.789C14.6116 21.4495 13.2886 21.7602 11.9999 21.7603C10.7112 21.7604 9.38811 21.4498 8.33033 20.7895L3.10846 17.5296C2.75709 17.3103 2.65007 16.8476 2.86942 16.4963C3.08876 16.1449 3.55142 16.0379 3.90279 16.2572L9.12465 19.517C9.89767 19.9996 10.9316 20.2604 11.9998 20.2603C13.068 20.2602 14.1018 19.9994 14.8747 19.5167L20.1636 16.2139C20.5149 15.9945 20.9776 16.1014 21.197 16.4527Z" />
    </svg>

)


export default Stack
