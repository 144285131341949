import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import Errors from '../components/FormItems/error/errors';
import i18n from '../i18n';

export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

async function findMe() {
  const response = await axios.get(`/users/${localStorage.getItem('userId')}`);
  return response.data;
}

export function authError(payload) {
  return {
    type: AUTH_FAILURE,
    payload,
  };
}

export function doInit() {
  return async (dispatch) => {
    let currentUser = null;

    try {
      currentUser = await findMe();
      if (currentUser.role === 'ROLE_ADMIN') {
        dispatch(push('/admin'));
      } else {
        dispatch(push('/app'));
      }

      dispatch({
        type: AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });

    } catch (error) {
      console.log(error);
      // Errors.handle(error);

      dispatch({
        type: AUTH_INIT_ERROR,
        payload: error,
      });
    }

  }
}

export function logoutUser() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userCompanyLogo');
    axios.defaults.headers.common['Authorization'] = "";

    dispatch(push('/login'));

    dispatch({
      type: LOGOUT_SUCCESS,
    });

  };
}

export function receiveToken(token, id, logo) {
  return (dispatch) => {
    localStorage.setItem('token', token);
    localStorage.setItem('userId', id);
    localStorage.setItem('userCompanyLogo', logo);
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    dispatch({
      type: LOGIN_SUCCESS
    });
  }
}

export function loginUser(creds) {
  return (dispatch) => {
    localStorage.setItem("dashboardTheme", 'dark')
    localStorage.setItem('navbarColor', '#fff')
    localStorage.setItem('navbarType', 'static')

    dispatch({
      type: LOGIN_REQUEST,
    });
    if (creds.email.length > 0 && creds.password.length > 0) {
      axios.post("/users/app/login", {
        username: creds.email,
        password: creds.password,
      }).then(res => {
        const { token, id, companyLogo } = res.data;
        dispatch(receiveToken(token, id, companyLogo || ''));
        dispatch(doInit());
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    } else {
      dispatch(authError(i18n.t('Something was wrong. Try again')));
    }

  };
}

export function verifyEmail(token) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      axios.put("/auth/verify-email", { token }).then(verified => {
        if (verified) {
          toast.success("Your email was verified");
        }
      }).catch(err => {
        toast.error(err.response.data);
      }).finally(() => {
        dispatch(push('/login'));
      })
    }
  }
}

export function resetPassword(token, plainPassword) {
  return (dispatch) => {
    dispatch({ type: RESET_REQUEST });

    axios.post(`/users/reset-password/${token}`, { plainPassword }).then(res => {
      dispatch({
        type: RESET_SUCCESS,
      });
      toast.success(i18n.t("Password has been updated"));
      dispatch(push('/login'));
    }).catch(err => {
      dispatch(authError(err.response.data));
      const { message } = err.response.data;
      toast.error(i18n.t(message));
    })
  }
}

export function sendPasswordResetEmail(email) {
  return (dispatch) => {
    dispatch({
      type: PASSWORD_RESET_EMAIL_REQUEST,
    });

    axios.post("/users/forgot-password", { email }).then(res => {
      dispatch({
        type: PASSWORD_RESET_EMAIL_SUCCESS,
      });
      toast.success(i18n.t("Email with resetting instructions has been sent"));
      dispatch(push('/login'));
    }).catch(err => {
      dispatch(authError(err.response.data));
      const { message } = err.response.data;
      toast.error(i18n.t(message));
    })
  }
}

export function registerUser(creds) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/user/profile'));
    } else {
      dispatch({
        type: REGISTER_REQUEST,
      });

      if (creds.email.length > 0 && creds.password.length > 0) {
        axios.post("/auth/signup", creds).then(res => {
          dispatch({
            type: REGISTER_SUCCESS
          });
          toast.success("You've been registered successfully. Please check your email for verification link");
          dispatch(push('/user/profile'));
        }).catch(err => {
          dispatch(authError(err.response.data));
        })

      } else {
        dispatch(authError(i18n.t('Something was wrong. Try again')));
      }
    }
  };
}
