import React, { useCallback, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../config";

const PrintButton = ({ orderId, type }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const typeTextMap = useMemo(() => ({
        "pdf": t("Print pdf"),
      
    }), [t]);

    const handlePrint = useCallback(async () => {
        setLoading(true);
        try {
            const resp = await axios.get(`/orders/${orderId}/${type}`);
            window.open(config.baseURL + "/" + resp.data.file_path, "_blank");
        } catch (e) {
            console.error(e);
            toast.error(t("Error while printing"));
        } finally {
            setLoading(false);
        }
    }, [orderId, type, t]);

    return (
        <Button className="ml-3" color="primary" onClick={handlePrint}>
                <>
                {loading ? <i className="la la-spinner la-spin" /> : <i className="fa fa-print"></i>}
                <span className="ms-2">{typeTextMap[type] || typeTextMap["default"]}</span>
                     
                </>
        
        </Button>
    );
};

export default PrintButton;