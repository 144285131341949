import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useRouteMatch, Route } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';
import classnames from 'classnames';

import s from './LinksGroup.module.scss';

const LinksGroup = ({
  header,
  link,
  childrenLinks,
  iconName,
  className,
  badge,
  label,
  activeItem,
  isHeader,
  index,
  deep = 0,
  onActiveSidebarItemChange,
  labelColor,
  exact = true,
  target,
  iconElement,
}) => {
  const [headerLinkWasClicked, setHeaderLinkWasClicked] = useState(true);

  const match = useRouteMatch({
    path: link,
    exact,
  });

  const togglePanelCollapse = (link) => {
    onActiveSidebarItemChange(link);
    setHeaderLinkWasClicked(
      !headerLinkWasClicked || (activeItem && !activeItem.includes(index)),
    );
  };

  const isOpen =
    activeItem &&
    activeItem.includes(index) &&
    headerLinkWasClicked;

  if (!childrenLinks) {
    if (isHeader) {
      return (
        <li className={classnames('link-wrapper', s.headerLink, className)}>
          <NavLink
            to={link}
            activeClassName={s.headerLinkActive}
            exact={exact}
            target={target}
          >
            <span className={classnames('icon', s.icon)}>
              {iconElement || <i className={`fi ${iconName}`} />}
            </span>
            {header}
            {label && (
              <sup className={`${s.headerLabel} ${s.headerUpdate}`}>
                {label}
              </sup>
            )}
            {badge && <Badge className={s.badge} pill color="danger">9</Badge>}
          </NavLink>
        </li>
      );
    }

    return (
      <li>
        <NavLink
          to={link}
          activeClassName={s.headerLinkActive}
          style={{ paddingLeft: `${26 + 10 * (deep - 1)}px` }}
          onClick={(e) => {
            if (link.includes('menu')) {
              e.preventDefault();
            }
          }}
          exact={exact}
        >
          {header}
          {label && <sup className={s.headerLabel}>{label}</sup>}
        </NavLink>
      </li>
    );
  }

  return (
    <Route
      path={link}
      children={(params) => {
        const { match } = params;
        return (
          <li
            className={classnames(
              'link-wrapper',
              { [s.headerLink]: isHeader },
              className
            )}
          >
            <a
              className={classnames(
                { [s.headerLinkActive]: match },
                { [s.collapsed]: isOpen },
                'd-flex'
              )}
              style={{
                paddingLeft: `${
                  deep === 0 ? 50 : 26 + 10 * (deep - 1)
                }px`,
              }}
              onClick={() => togglePanelCollapse(link)}
            >
              {isHeader && (
                <span className={classnames('icon', s.icon)}>
                  {iconElement || <i className={`fi ${iconName}`} />}
                </span>
              )}
              {header}
              {label && (
                <sup className={`${s.headerLabel} ${s.headerNode}`}>
                  {label}
                </sup>
              )}
              <b className={['fa fa-angle-left', s.caret].join(' ')} />
            </a>
            <Collapse className={s.panel} isOpen={isOpen}>
              <ul>
                {childrenLinks &&
                  childrenLinks.map((child, ind) => (
                    <LinksGroup
                      onActiveSidebarItemChange={onActiveSidebarItemChange}
                      activeItem={activeItem}
                      header={child.header}
                      link={child.link}
                      index={child.index}
                      childrenLinks={child.childrenLinks}
                      deep={deep + 1}
                      key={ind}
                    />
                  ))}
              </ul>
            </Collapse>
          </li>
        );
      }}
    />
  );
};

LinksGroup.propTypes = {
  header: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  childrenLinks: PropTypes.array,
  iconName: PropTypes.string,
  className: PropTypes.string,
  badge: PropTypes.string,
  label: PropTypes.string,
  activeItem: PropTypes.string,
  isHeader: PropTypes.bool,
  index: PropTypes.string,
  deep: PropTypes.number,
  onActiveSidebarItemChange: PropTypes.func,
  labelColor: PropTypes.string,
  exact: PropTypes.bool,
  target: PropTypes.string,
  iconElement: PropTypes.node,
};

LinksGroup.defaultProps = {
  link: '',
  childrenLinks: null,
  className: '',
  isHeader: false,
  deep: 0,
  activeItem: '',
  label: '',
  exact: true,
};

export default LinksGroup;