import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, Label, Input, FormFeedback, Container, Col, Row } from "reactstrap";
import Widget from "../../../components/Widget";
import Logo from '../../../images/logo.svg';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { authError, doInit, receiveToken } from "../../../actions/auth";

const initialValues = {
    user: {
        phone: "",
        email: "",
        username: "",
        plainPassword: "",
        repeatPassword: "",
    },
    company: {
        organizationNr: "",
        name: "",
        description: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postNr: "",
        country: "",
        typeId: null,
        signatureEmail: "",
        signatureName: "",
    },
    userConfirm: false,
};

const validationSchema = Yup.object().shape({
    user: Yup.object().shape({
        phone: Yup.string()
            .matches(/^\+?[0-9]{10,15}$/, "Invalid phone number")
            .required("Phone number is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        username: Yup.string().required("Username is required"),
        plainPassword: Yup.string().required("Password is required"),
        repeatPassword: Yup.string()
            .oneOf([Yup.ref('plainPassword'), null], "Passwords must match")
            .required("Repeat password is required"),
    }),
    company: Yup.object().shape({
        name: Yup.string().required("Company name is required"),
        typeId: Yup.string().nullable().required("Company type is required"),
    }),
    userConfirm: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),

});

const RegisterForm = () => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const dispatch = useDispatch();


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setErrors }) => {
            setLoading(true);
            axios.post("/users/register", {
                ...values,
                company: {
                    ...values.company,
                    typeId: parseInt(values.company.typeId),
                },

            })
                .then((response) => {
                    // if the response is successful (status code 2xx), use username and password to login
                    setLoggingIn(true);
                    axios.post("/users/app/login", {
                        username: values.user.username,
                        password: values.user.plainPassword,
                    }).then(res => {
                        const { token, id } = res.data;
                        // dispatch(receiveToken(token, id));
                        localStorage.setItem('token', token);
                        localStorage.setItem('userId', id);
                        axios.defaults.headers.common['Authorization'] = "Bearer " + token;

                        dispatch(doInit());
                        setTimeout(() => {
                            dispatch(push('/app/quotes'));
                        });
                    }).catch(err => {
                        dispatch(authError(err.response.data));
                    }).finally(() => {
                        setLoggingIn(false);
                    });


                })
                .catch((error) => {
                    setErrors(error.response.data);
                })
                .finally(() => {

                    setLoading(false);
                });

        },
    });


    const { handleChange, handleBlur, handleSubmit, values, touched, errors, isSubmitting } = formik;

    useEffect(() => {
        // Fetch options for typeId
        const fetchOptions = async () => {
            try {
                const response = await axios.get("/options-list/company_type?limit=1000");
                setOptions(response.data); // Assuming the API response returns an array of options
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        };
        fetchOptions();
    }, []);

    return (
        <div className="auth-page">
            <Container>
                <div className="auth-logo d-flex justify-content-center">
                    <img src={Logo} alt="Logo" />
                </div>
                <Widget className="mx-auto p-3" title={<h3 className="mt-0">{t("Apply new account")}</h3>}>
                    <form onSubmit={handleSubmit} noValidate>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>{t("Type of company")}</Label>

                                    <div className={(touched.company?.typeId && !!errors.company?.typeId) ? 'is-invalid' : ''}>
                                        {options.length > 0 ? (
                                            options.map((option, index) => (
                                                <div>
                                                    <div key={index} className={"form-check " + (index === 0 ? "me-5" : "")}>
                                                        <Input
                                                            type="radio"
                                                            name="company.typeId"
                                                            id={`type-${option.id}`}
                                                            value={option.id}
                                                            checked={values.company.typeId === String(option.id)}
                                                            onChange={handleChange}
                                                            className="form-check-input"
                                                            invalid={touched.company?.typeId && !!errors.company?.typeId}

                                                        />
                                                        <Label
                                                            for={`type-${option.id}`}
                                                            className="form-check-label"
                                                        >
                                                            {t(option.name)}
                                                        </Label>
                                                    </div>
                                                    <p>{t(`description-${option.id === "2" ? "seller" : "buyer"}`)}</p>
                                                </div>
                                            ))
                                        ) : ''}
                                    </div>

                                    {touched.company?.typeId && errors.company?.typeId && (

                                        <FormFeedback>{t(errors.company.typeId)}</FormFeedback>

                                    )}
                                </FormGroup>
                            </Col>
                        </Row>



                        {/* Company Fields */}
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-name">{t("Company Name")}</Label>
                                    <Input
                                        name="company.name"
                                        id="company-name"
                                        value={values.company.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.company?.name && !!errors.company?.name}
                                    />
                                    {touched.company?.name && errors.company?.name && (
                                        <FormFeedback>{t(errors.company.name)}</FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-address">{t("Address")}</Label>
                                    <Input
                                        name="company.address"
                                        id="company-address"
                                        value={values.company.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-postNr">{t("Post Number")}</Label>
                                    <Input
                                        name="company.postNr"
                                        id="company-postNr"
                                        value={values.company.postNr}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-city">{t("City")}</Label>
                                    <Input
                                        name="company.city"
                                        id="company-city"
                                        value={values.company.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-country">{t("Country")}</Label>
                                    <Input
                                        name="company.country"
                                        id="company-country"
                                        value={values.company.country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-organizationNr">{t("Organization Number")}</Label>
                                    <Input
                                        name="company.organizationNr"
                                        id="company-organizationNr"
                                        value={values.company.organizationNr}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-email">{t("Company Email")}</Label>
                                    <Input
                                        name="company.email"
                                        id="company-email"
                                        value={values.company.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-phone">{t("Phone")}</Label>
                                    <Input
                                        name="company.phone"
                                        id="company-phone"
                                        value={values.company.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-signatureEmail">{t("Signature email")}</Label>
                                    <Input
                                        name="company.signatureEmail"
                                        id="company-signatureEmail"
                                        value={values.company.signatureEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="company-signatureName">{t("Signature name")}</Label>
                                    <Input
                                        name="company.signatureName"
                                        id="company-signatureName"
                                        value={values.company.signatureName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <hr />

                        {/* User Fields */}
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="user-username">{t("Username")}</Label>
                                    <Input
                                        name="user.username"
                                        id="user-username"
                                        value={values.user.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.user?.username && !!errors.user?.username}
                                    />
                                    {touched.user?.username && errors.user?.username && (
                                        <FormFeedback>{t(errors.user.username)}</FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="user-email">{t("Email")}</Label>
                                    <Input
                                        name="user.email"
                                        id="user-email"
                                        type="email"
                                        value={values.user.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.user?.email && !!errors.user?.email}
                                    />
                                    {touched.user?.email && errors.user?.email && (
                                        <FormFeedback>{t(errors.user.email)}</FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="user-phone">{t("Phone")}</Label>
                                    <Input
                                        name="user.phone"
                                        id="user-phone"
                                        value={values.user.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.user?.phone && !!errors.user?.phone}
                                    />
                                    {touched.user?.phone && errors.user?.phone && (
                                        <FormFeedback>{t(errors.user.phone)}</FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}><FormGroup>
                                <Label for="user-plainPassword">{t("Password")}</Label>
                                <Input
                                    name="user.plainPassword"
                                    id="user-plainPassword"
                                    type="password"
                                    value={values.user.plainPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={touched.user?.plainPassword && !!errors.user?.plainPassword}
                                />
                                {touched.user?.plainPassword && errors.user?.plainPassword && (
                                    <FormFeedback>{t(errors.user.plainPassword)}</FormFeedback>
                                )}
                            </FormGroup>
                            </Col>
                            <Col md={6}> <FormGroup>
                                <Label for="user-repeatPassword">{t("Repeat password")}</Label>
                                <Input
                                    name="user.repeatPassword"
                                    id="user-repeatPassword"
                                    type="password"
                                    value={values.user.repeatPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={touched.user?.repeatPassword && !!errors.user?.repeatPassword}
                                />
                                {touched.user?.repeatPassword && errors.user?.repeatPassword && (
                                    <FormFeedback>{t(errors.user.repeatPassword)}</FormFeedback>
                                )}
                            </FormGroup></Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup className="form-check">
                                    <Input
                                        name="userConfirm"
                                        id="userConfirm"
                                        type="checkbox"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched?.userConfirm && !!errors?.userConfirm}
                                    />
                                    <Label for="userConfirm" dangerouslySetInnerHTML={{ __html: t("userTerms") }} />
                                    {touched?.userConfirm && errors?.userConfirm && (
                                        <FormFeedback>{t(errors.userConfirm)}</FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="mt-3 mb-3" />
                        <div className="d-flex justify-content-center">
                            <Button type="submit" color="primary">
                                {loading ? <i className="fa fa-spinner fa-spin me-2" /> : ""}
                                {loading ? t("Creating account") : (loggingIn ? t("Logging in") : t("Create account"))}
                            </Button>
                        </div>

                    </form>
                </Widget>
            </Container>
        </div>
    );
};

export default RegisterForm;
