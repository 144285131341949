import React from 'react';
import { createRoot } from "react-dom/client";
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

import App from './components/App';
import config from './config';
import createRootReducer from './reducers';
import { logoutUser } from "./actions/auth";
import { doInit } from './actions/auth';
import { createBrowserHistory } from 'history';
import "./i18n";

const history = createBrowserHistory();

export function getHistory() {
  return history;
}

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = "application/json";
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logoutUser());
    } 
    // else if (error.response.status === 404) {
    //   //toast.error(error.response.data.message);
    //   const path = window.location.pathname;
    // }
    return Promise.reject(error);
  }
);

export const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
      ReduxThunk
    ),
  )
);

// check if current path is login or register or forgot password or reset password page, then do not dispatch doInit action
// const path = window.location.pathname;
// if (path !== '/login' && path !== '/register' && path !== '/forgot' && path.indexOf('/password-reset/') === -1 && path.indexOf('/esign') === -1) {
  store.dispatch(doInit());
// }

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
