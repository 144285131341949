import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import Widget from '../../../components/Widget';
import { sendPasswordResetEmail } from '../../../actions/auth';
import { useTranslation } from "react-i18next";
import Logo from '../../../images/logo.svg';
import { Form, Formik } from 'formik';
import formValidations from '../../../components/FormItems/formValidations';
import InputFormItem from '../../../components/FormItems/items/InputFormItem';
import Loader from '../../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';

const Forgot = () => {
  const { t } = useTranslation();

  const schema = {
    email: { type: "email", label: "Email", required: true },
  };

  const { isFetching } = useSelector(state => state.auth);
  
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(sendPasswordResetEmail(values.email));
  };

  return (
    <div className="auth-page">
      <Container>
        <div className="auth-logo d-flex justify-content-center">
          <img src={Logo} alt={t("Logo")} />
        </div>
        <Widget className="widget-auth mx-auto" title={<h3 className="mt-0">{t("Forgot password?")}</h3>}>
          <Formik
            onSubmit={handleSubmit}
            enableReinitialize
            initialValues={{ email: "" }}
            validationSchema={() => formValidations(schema)}
          >
            {({ isValid, isSubmitting }) => (
              <Row>
                <Col md={12}>
                  <Form>
                    <InputFormItem
                      name={"email"}
                      label={t("Email")}
                      required
                      schema={schema}
                    />
                    <Button
                      className="auth-btn mb-3"
                      color="inverse"
                      type="submit"
                      size="sm"
                      disabled={isFetching || !isValid}
                    >
                      {(isFetching) ? (
                        <Loader />
                      ) : t("Send")}
                    </Button>
                  </Form>
                </Col>
              </Row>
            )}
          </Formik>
        </Widget>
      </Container>
    </div>
  );
};

export default Forgot;